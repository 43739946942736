import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { filter, pipe, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ToastService } from '@ideals/services/toast';
import { downloadFile } from '@ideals/utils/download-file';

import { Article, Country, HelpCenterConfig, SearchArticle, Video } from '../models';
import { CountryCodeService, DEFAULT_COUNTRY_CODE } from '../services/country-code';
import { HelpCenterService } from '../services/help-center';
import { getArticleForSection } from '../utils/get-article-for-section';

interface HelpCenterState extends HelpCenterConfig {
  readonly allArticles: Article[];
  readonly articlesIndex: SearchArticle[];
  readonly articlesIndexLoading: boolean;
  readonly articlesLoading: boolean;
  readonly countries: Country[];
  readonly countriesLoading: boolean;
  // TODO: Please use CountryCode type
  readonly selectedCountryCode: string;
  readonly selectedCountryCodeLoading: boolean;
  readonly videos: Video[];
  readonly videosLoading: boolean;
}

const initialState: HelpCenterState = {
  allArticles: [],
  articlesIndex: [],
  articlesIndexLoading: false,
  articlesLoading: false,
  countries: [],
  countriesLoading: false,
  language: '',
  section: '',
  selectedCountryCode: DEFAULT_COUNTRY_CODE,
  selectedCountryCodeLoading: false,
  videos: [],
  videosLoading: false,
};

export const ALL_ARTICLES_FILE_NAME = 'IdealsManual.html';

export const HelpCenterStore = signalStore(
  { protectedState: false },
  withState(initialState),
  withComputed(({
    allArticles,
    articlesIndexLoading,
    articlesLoading,
    countries,
    countriesLoading,
    section,
    selectedCountryCode,
    selectedCountryCodeLoading,
  }) => ({
    articles: computed(() => getArticleForSection(allArticles(), section())),
    contactsLoading: computed(() => countriesLoading() || selectedCountryCodeLoading()),
    searchLoading: computed(() => articlesLoading() || articlesIndexLoading()),
    selectedCountry: computed(() => countries().find(({ countryCode }) => countryCode === selectedCountryCode())) ?? countries()[0],
  })),
  withMethods((store) => {
    const countryCodeService = inject(CountryCodeService);
    const helpCenterService = inject(HelpCenterService);
    const toastService = inject(ToastService);
    const translateService = inject(TranslateService);

    const requestError = (): void => {
      toastService.error(translateService.instant('common.Error.Something_went_wrong') as string);
    };

    const loadArticles = rxMethod<void>(
      pipe(
        tap(() => patchState(store, { articlesLoading: true })),
        switchMap(() => helpCenterService.loadArticles(store.language()!).pipe(
          tapResponse(
            (allArticles) => patchState(store, { allArticles, articlesLoading: false }),
            () => {
              requestError();
              patchState(store, { articlesLoading: false });
            },
          )
        )),
      ),
    );

    const loadArticlesIndex = rxMethod<void>(
      pipe(
        tap(() => patchState(store, { articlesIndexLoading: true })),
        switchMap(() => helpCenterService.loadArticlesIndex(store.language()!).pipe(
          tapResponse(
            (articlesIndex) => patchState(store, { articlesIndex, articlesIndexLoading: false }),
            () => {
              requestError();
              patchState(store, { articlesIndexLoading: false });
            },
          )
        )),
      ),
    );

    const loadCountries = rxMethod<void>(
      pipe(
        tap(() => patchState(store, { countriesLoading: true })),
        switchMap(() => helpCenterService.loadCountries(store.language()!).pipe(
          tapResponse(
            (countries) => patchState(store, { countries, countriesLoading: false }),
            () => {
              requestError();
              patchState(store, { countriesLoading: false });
            },
          )
        )),
      ),
    );

    const loadVideos = rxMethod<void>(
      pipe(
        tap(() => patchState(store, { videosLoading: true })),
        switchMap(() => helpCenterService.loadVideos().pipe(
          tapResponse(
            (videos) => patchState(store, { videos, videosLoading: false }),
            () => {
              requestError();
              patchState(store, { videosLoading: false });
            },
          )
        )),
      ),
    );

    const loadSelectedCountryCode = rxMethod<void>(
      pipe(
        tap(() => patchState(store, { selectedCountryCodeLoading: true })),
        switchMap(() => countryCodeService.getCountryCode$().pipe(
          tapResponse(
            (selectedCountryCode) => patchState(store, { selectedCountryCode, selectedCountryCodeLoading: false }),
            () => {
              requestError();
              patchState(store, { selectedCountryCodeLoading: false });
            },
          )
        )),
      ),
    );

    return {
      downloadArticles: rxMethod<void>(
        pipe(
          switchMap(() => helpCenterService.loadAllArticlesFile(store.language()!).pipe(
            tapResponse(
              (file) => downloadFile(file, ALL_ARTICLES_FILE_NAME),
              () => requestError(),
            )
          )),
        ),
      ),

      setConfig: rxMethod<HelpCenterConfig>(
        pipe(
          filter((config) => {
            const loadData = store.language() !== config.language;

            patchState(store, config);

            return loadData;
          }),
          tap(() => {
            loadVideos();
            loadArticles();
            loadCountries();
            loadArticlesIndex();
            loadSelectedCountryCode();
          }),
        ),
      ),

      setSelectedCountryCode: (selectedCountryCode: string) => {
        patchState(store, { selectedCountryCode });
        countryCodeService.saveCountryCode(selectedCountryCode);
      },
    };
  }),
);
